@import '../variables';

.carousel {
  position: relative;
  ul li {
    .slide-content-layer {
      width: 100%;
      position: absolute;
      bottom: 41%;
      .slide-content {
        max-width: 1215px;
        display: block;
        height: inherit;
        position: relative;
        top: 4rem;
        .slide-text{
          height: 100%;
          margin-bottom: 2rem;
          p{
            color:@full-white;
            line-height: 1;
            position: relative;
          }
          p.carousel-tagline {
            font-size: 2rem;
            margin-bottom: 0.9vw;
          }
          p:not(.carousel-tagline) {
            text-transform: uppercase;
            font-size: 2vw;
          }
          p.carousel-description{
            text-transform: uppercase;
            padding-bottom: 1.1vw;
            margin-bottom: 0.9vw;
            padding-top: 1.1vw;
            &:before { 
              content: '';
              position: absolute;
              height: 1px;
              left: 0;
              top: 0;
              border-top: 1px dashed @full-white;
              width: 28%;
            }
            &:after { 
              content: '';
              position: absolute;
              height: 1px;
              left: 0;
              bottom: 0;
              border-top: 1px dashed @full-white;
              width: 28%;
            }
          }
          &.image-3{
            p{
              color: #444444;
              font-weight: 100;
              &:after{
                border-top: 1px dashed #444444;
              }
              &:before{
                border-top: 1px dashed #444444;
              }
              strong{
                color:@primary;
              }
            }
          }
        }
        .carousel-heading {
          font-size: 30px;
          color: #fff;
          padding-bottom: 20px;
          border-bottom: 1px dashed;
          text-transform: uppercase;
        }
        .carousel-marketing-text {
          font-size: 60px;
          padding: 20px 0;
          border-bottom: 1px dashed;
          color: #fff;
          text-transform: uppercase;
        }
        .button {
          text-transform: uppercase;
          margin-top: 20px;
          max-width: 210px;
          padding: 1rem 1rem;
        }
      }
    }
    .overlay {
      position: absolute;
      bottom: 4px;
      left: 0;
      max-height: 23.5rem;
    }
  }
  .carousel-item-markers {
    position: absolute;
    bottom: 19%;
    width: 100%;
    left: 0;
    ol {
      width: 100%;
      max-width: 1024px;
      display: flex;
      align-items: center;
      //.item-marker {
        .slide-marker {
        display: inline-block;
        margin: 4px;
        border: 0;
        cursor: pointer;
        font-size: 1rem;
        &:before {
          content: '\f111';
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          color:rgba(255,255,255, .5);
        }
        &:hover:not(.active):before{
          color:#fff;
          font-size:1rem;
        }
        &.active:before {
          font-size:1.5rem;
          color: #fff;
        }
        &.unused-marker {
          display: none;
        }
      }
    }
  }
  .slides .slide {
    flex: 1 0 100%;
    .main-image {
      display: flex;
    }
  }
  &[data-carousel-transition=fade] {
    .slides {
      display:grid;
      display:-ms-grid;
      .slide{
        opacity:0;
        grid-column:1;
        -ms-grid-column: 1;
        grid-row:1;
        -ms-grid-row: 1;
        width:100%;
        z-index: -1;
      }
    }
  }
}


@media only screen and (min-width: 1940px) {
  .carousel {
    position: relative;

    .carousel-item-markers {
      position: absolute;
      bottom: 19%;
      width: 100%;
      left: 0;
      ol {
        width: 100%;
        max-width: 1024px;
        display: flex;
        align-items: center;
        //.item-marker {
          .slide-marker {
          display: inline-block;
          margin: 4px;
          border: 0;
          cursor: pointer;
          font-size: 1rem;
          &:before {
            content: '\f111';
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            color:rgba(255,255,255, .5);
          }
          &:hover:not(.active):before{
            color:#fff;
            font-size:1rem;
          }
          &.active:before {
            font-size:1.5rem;
            color: #fff;
          }
          &.unused-marker {
            display: none;
          }
        }
      }
    }

    ul li {
      .overlay{
        margin: auto;  
        max-width: 50em;
        max-height: 100em;  
        min-width: 100%;
      }
      .main-image{
        
        img{
          margin: auto;  
          max-width: 50em;
          max-height: 100em;  
          min-width: 100%;
        }

      }
    }
  }
}

@media @ipad-landscape {
  .carousel {
    ul li {
      .main-image{
        img{
          width:100%;
        }
      }
      .slide-content-layer {
        left:5%;
        .slide-content {
          left: 0;
          .carousel-heading {
            display: none;
          }
          .carousel-marketing-text {
            border-bottom: 0;
          }
          .button {
            margin-top: 0;
            max-width: 20rem;
          }
          .slide-text { 
            margin-bottom: 1rem;
            width: 47%;
            p.carousel-tagline {
              margin-bottom: 1rem;
            //  font-size: 1.8rem;
            }
            p:not(.carousel-tagline){
              font-size: 2.8vw;
            }
            p.carousel-description{
              margin-bottom: 1rem;
              padding-bottom: 1rem;
              padding-top: 1rem;
            }
          }
        }
      }
    }
    .carousel-item-markers {
      top: 109%;
      ol {
        justify-content: center;
          .slide-marker {
            font-size: 0.5rem;
            &.active:before {
              font-size:0.5rem;
              color: #f7941d;
            }
          }
        }
      }
    }
  }


@media @ipad-portrait { 
  .carousel {
    ul li {
        .slide-content-layer {
          bottom: 35%;
        }
      
      .slide-content-layer {
        .slide-content {
          top: 2rem;
          .slide-text {
            p:not(:first-of-type){
          //   font-size: 2rem;
            }
            p.carousel-description{
              &:after {
                display:none;
              }
              &:before {
                display:none;
              }
            }
          }
        }
      }
    }
  }
}

@media @mobile {
  .carousel {
    ul li {
      .overlay {
        display: none;
      }
      .slide-content-layer {
        max-width: 44%;
        .slide-content {
          margin: 0;
          padding: 0;
          top: 0;
          .slide-text {
            width:100%;
            p.carousel-tagline{
              display: none;
            }
            p:not(.carousel-tagline){
              font-size: 1.5rem;
            }
          }
          .carousel-heading {
            display: none;
          }
          .carousel-marketing-text {
            padding: 0;
            border-bottom: none;
            font-size: 30px;
          }
          .button {
            margin-top: 0;
            max-width: 10rem;
            position: relative;
            font-size: 1rem;
            padding: .5rem;
          }
        }
      }
    }
    .carousel-item-markers {
      top: 102%;
      ol .item-marker {
        font-size: 1rem;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .carousel {
    ul li {
      .slide-content-layer {
        bottom: 23%;
      }
    }
  }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .fallback-rules;
  .slide-ie-edge;
}

.slide-ie-edge {
  .carousel[data-carousel-transition='fade'] .slides .slide {
    position: relative;
  }
}

@supports (-ms-ime-align: auto) { //Edge
  .slide-ie-edge;
}

@supports not (display: grid) {
  .fallback-rules;
}

.fallback-rules {
  .carousel {
    height: auto;
    // max-height: auto;
    max-height: none; //max height auto not valid
  }
}